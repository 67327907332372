/* Reset styles */
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

/* Body Styles */
body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'MagnoliaThin', Arial, sans-serif; /* Default font for body */
  background-color: #000; /* Solid black background */
  color: #fff;
  margin: 0;
  padding: 0;
}

/* Title Styles - Apply MagnoliaThin */
p.title {
  font-family: 'MagnoliaThin', Arial, sans-serif; /* Use MagnoliaThin for title */
  font-size: 31px;
  text-align: center;
  font-weight: normal;
  margin: 20px 0 10px;
}

/* Button Styles */
#customButton {
  font-family: 'MagnoliaThin', Arial, sans-serif; /* Apply MagnoliaThin font to buttons */
  background-color: #B73E43; /* Default color */
  border-radius: 25px; /* More rounded edges */
  cursor: pointer;
  font-weight: 400;
  margin: 10px auto;
  min-width: 250px; /* Uniform minimum width for all buttons */
  max-width: 300px; /* Set a reasonable maximum width */
  padding: 10px 20px; /* Padding for balanced spacing */
  text-align: center;
  width: 100%; /* Ensures consistent width */
  color: #fff; /* White text for contrast */
  transition: background-color 0.3s, color 0.3s, transform 0.2s;
  display: flex;
  align-items: center; /* Center align items vertically */
  justify-content: center; /* Center text and icon horizontally */
  position: relative; /* For positioning the Christmas hat */
}

#customButton span {
  font-size: 16px; /* Text size remains consistent */
  line-height: 1; /* Consistent line height */
}

#customButton img {
  height: 1.2em; /* Adjusted size to align with text height */
  margin-left: 8px; /* Space between text and icon */
  margin-top: -2px; /* Slight adjustment to raise icon */
  width: auto; /* Maintain aspect ratio */
  vertical-align: middle; /* Align vertically */
}

#customButton:hover {
  background-color: #20630d; /* Original pink color now as hover */
  color: #fff;
  transform: translateY(-3px); /* Subtle lift effect */
}

/* Add Christmas hat on button */
#customButton::after {
  content: '';
  position: absolute;
  top: -15px; /* Adjust position of the hat */
  right: -15px; /* Adjust position of the hat */
  width: 40px; /* Adjust size of the hat */
  height: 40px; /* Adjust size of the hat */
  background-image: url('./images/christmashat.png'); /* Path to the Christmas hat */
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  z-index: 2; /* Ensure the hat appears above the button */
}


/* Icon inside button */
.buttonIcon {
  display: inline-block;
  height: 1.2em; /* Adjusted to align with text */
  width: auto; /* Maintain aspect ratio */
  margin-left: 8px; /* Space between icon and text */
  object-fit: contain;
  vertical-align: middle; /* Align icon with text */
  margin-top: -2px; /* Slight adjustment to raise icon */
}

/* App container */
.App {
  text-align: center;
}

.header-image {
  display: block;
  margin: 15px auto 5px;
  width: 300px;
}

/* Link and exclusive content colors */
.App-link,
.exclusiveContent {
  color: #00aeef;
}

/* Container styles */
.container {
  align-items: center;
  flex-direction: column;
  height: 90vh;
  justify-content: center;
  margin: 0 auto;
}

.locationText {
  font-size: 18px;
  font-weight: 400;
  margin: 30px 0;
  vertical-align: middle;
}

.onlineText {
  font-size: 12px;
  font-weight: 400;
  margin-top: -30px;
  vertical-align: middle;
}

.textContainer {
  padding: 0;
  text-align: center;
}

.detailsText {
  margin-top: -20px;
}

/* Swiper Slide Animation */
@keyframes rotate360 {
  0% {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(1turn);
  }
}

.swiper-slide img {
  animation: rotate365 10s linear infinite;
  height: auto;
  object-fit: cover;
  width: 100%;
}

/* Button Container */
.buttonContainer {
  display: grid;
  grid-template-columns: 1fr; /* Single column */
  gap: 10px; /* Add space between buttons */
  justify-content: center;
  margin: 20px 0;
}

.socialContainer {
  display: flex;
  justify-content: center;
  align-items: center; /* Align icons vertically */
  gap: 15px; /* Spacing between icons */
  margin: 15px auto; /* Center the container */
  padding: 10px 20px; /* Add vertical and horizontal padding */
  border-radius: 25px; /* Rounded edges, can be kept for consistency */
  width: fit-content; /* Make the container fit the content */
}

.socialIcon img {
  width: 32px;
  height: 32px;
  cursor: pointer;
  display: block;
}





