@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;600&display=swap');


/* Reset some base styles to avoid conflicts */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}




/* Style adjustments for the custom button */
#customButton {
  align-items: center;
  animation: bounce 1s infinite alternate;
  background-color: #00aeef;
  border-radius: 15px;
  color: #fff;
  cursor: pointer;
  display: flex;
  font-weight: 700;
  justify-content: center;
  margin: 10px auto;
  max-width: 350px;
  padding: 10px;
  text-align: center;
  text-decoration: none;
  width: 100%;
}

#customButton img {
  height: 1em;
  margin-left: 5px;
  margin-top: 5px;
  width: 1em;
}

/* Keyframes for the bounce animation */
@keyframes bounce {
  0% {
    transform: translateY(0);
  }

  to {
    transform: translateY(-5px);
  }
}

/* Make sure code elements have a consistent font */
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}